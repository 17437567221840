<ng-container *transloco="let t">
  <ng-container
    *ngIf="{
      assetListOptions: assetListOptions$ | async,
      assetList: assetList$ | async,
      currencies: currencies$ | async,
      forecastOptions: forecastOptions$ | async,
    } as data"
  >
    <ng-container *ngIf="data.assetList != null && data.currencies != null; else loading">
      <form [formGroup]="form" class="forecast-options">
        <div class="field">
          <label class="mat-subheading-2 currency-label">{{ t('forecast.currency') }}</label>
          <mat-form-field class="am-form-field currency-field" appearance="fill">
            <mat-select disableRipple="true" hideSingleSelectionIndicator="true" formControlName="currency">
              <mat-option *ngFor="let currency of data.currencies" [value]="currency.label">
                {{ currency.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="field">
          <label class="mat-subheading-2">{{ t('forecast.hedging') }}</label>
          <mat-button-toggle-group
            class="hedge-field"
            hideSingleSelectionIndicator
            #group="matButtonToggleGroup"
            formControlName="isHedge"
          >
            <mat-button-toggle [value]="false">{{ t('forecast.unhedged') }}</mat-button-toggle>
            <mat-button-toggle [value]="true">{{ t('forecast.hedged') }}</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <div class="field">
          <label class="mat-subheading-2">{{ t('forecast.asset-classes') }}</label>
          <am-multiselect-with-search
            [options]="data.assetListOptions"
            formControlName="assetClasses"
          ></am-multiselect-with-search>
        </div>
      </form>
      <ng-container *ngIf="data.forecastOptions">
        <div class="graph am-box-shadow" echarts [options]="data.forecastOptions"></div>
      </ng-container>
    </ng-container>
    <ng-container></ng-container>
    <ng-template #loading>
      <div class="am-loading">
        <mat-spinner class="am-spinner" [diameter]="70"></mat-spinner>
        {{ t('forecast.loading') }}
      </div>
    </ng-template>
  </ng-container>
</ng-container>

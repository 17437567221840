import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AvailableLang } from '@app/core/const/i18n';
import * as fromEntities from '@app/core/entities/reducers';
import { Asset, Currency } from '@app/core/models';
import { SelectOption } from '@app/shared/components';
import * as fromRoot from '@app/store/reducers';
import { TranslocoService } from '@ngneat/transloco';
import { select, Store } from '@ngrx/store';
import { EChartsOption } from 'echarts';
import { isEmpty } from 'lodash';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';

import { ForecastChartsService } from '../services/forecast-charts.service';

export const forcastAssetClassesKey = 'forcast-assetclasses';

@Component({
  selector: 'am-forecast',
  templateUrl: './forecast.component.html',
  styleUrls: ['./forecast.component.scss'],
})
export class ForecastComponent implements OnInit, OnDestroy {
  currencies$: Observable<Currency[]>;

  assetList$: Observable<Asset[]>;

  assetListOptions$: Observable<SelectOption[]>;

  forecastOptions$: Observable<EChartsOption>;

  currentLang$: Observable<AvailableLang>;

  form: FormGroup;

  private _destroy$: Subject<void> = new Subject();

  private _changeForecastParamsSub = new BehaviorSubject<void>(undefined);

  constructor(
    private readonly _store: Store<fromRoot.State>,
    private readonly _forecastChartsService: ForecastChartsService,
    private readonly _translocoService: TranslocoService,
    private readonly _formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    const initialAssetClasses = this._getPersistedAssetClasses();
    this.form = this._formBuilder.group({
      currency: new FormControl(null),
      isHedge: new FormControl(false),
      assetClasses: new FormControl(initialAssetClasses ?? []),
    });
    this.currencies$ = this._store.pipe(
      select(fromEntities.getAllCurrencies),
      filter(currencies => currencies && currencies.length > 0),
      take(1),
      tap(currencies => {
        this.form.get('currency').setValue(currencies[0].label);
      })
    );

    this.assetList$ = this._store.select(fromEntities.getAllAssets).pipe(filter(assets => assets && assets.length > 0));

    this.assetListOptions$ = combineLatest([this.assetList$, this._store.select(fromRoot.getCurrentLang)]).pipe(
      takeUntil(this._destroy$),
      map(([assetList, currentLang]) =>
        assetList.map(assetClass => ({
          label: assetClass.name[currentLang],
          value: assetClass.id,
        }))
      ),
      tap(assetClassList => {
        if (initialAssetClasses == null) {
          this.form.get('assetClasses').setValue(assetClassList.map(assetClass => assetClass.value));
        }
      })
    );
    this.currentLang$ = this._translocoService.selectTranslateObject('axis-label', {}, 'forecast').pipe(
      takeUntil(this._destroy$),
      filter(selectTranslateObject => !isEmpty(selectTranslateObject)),
      withLatestFrom(this._store.select(fromRoot.getCurrentLang)),
      map(([_, currentLang]) => currentLang)
    );
    this.forecastOptions$ = combineLatest([
      this.form.valueChanges,
      this.currentLang$,
      this.assetList$,
      this.currencies$,
    ]).pipe(
      takeUntil(this._destroy$),
      tap(([{ assetClasses }]) => {
        this._persistAssetClasses(assetClasses);
      }),
      map(([{ assetClasses, currency, isHedge }, currentLang, assets]) => {
        return this._forecastChartsService.getForecastOptions(
          currency,
          isHedge,
          assets.filter(asset => assetClasses.includes(asset.id)),
          currentLang
        );
      })
    );
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private _persistAssetClasses(assetClasses: number[]): void {
    localStorage.setItem(forcastAssetClassesKey, JSON.stringify(assetClasses));
  }

  private _getPersistedAssetClasses(): number[] | null {
    const initialAssetClassesString = localStorage.getItem(forcastAssetClassesKey);
    if (initialAssetClassesString == null) {
      return null;
    }
    return JSON.parse(initialAssetClassesString);
  }
}

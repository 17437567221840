import { AvailableCountry } from '@app/core/const/country';
import { AvailableLang } from '@app/core/const/i18n';

export type Disclaimer = {
  displayOn: string;
  disclaimer: Partial<Record<AvailableCountry, Partial<Record<AvailableLang, string[]>>>>;
};

/* eslint-disable max-len */
export const DISCLAIMERS: Disclaimer[] = [
  {
    displayOn: '',
    disclaimer: {
      FRA: {
        fr: [
          "Cet outil à but pédagogique a pour vocation de faire des simulations d'allocations stratégiques long terme (entre 8 et 10 ans). Les informations données par cet outil le sont sans prendre en considération votre situation personnelle et notamment votre situation financière et votre profil de risque. Par exception, certaines classes d'actif peuvent ne pas vous être présentées compte tenu du profil financier sélectionné.",
          'Les rendements réels qui pourront être réalisés pourront différer de ceux présentés dans ces simulations.',
        ],
        en: [
          'This tool aims at making long-term (8 to 10 years) strategic asset allocations simulations with an educational purpose. Information available in this tool are provided without taking into account your personal situation, and more particularly your financial situation, and your risk profile. On an exception basis, some asset classes may be not presented to you in order to be in line with the selected financial profile.',
          'Actual returns, which can be achieved, can differ from those presented in these simulations.',
        ],
      },
      BEL: {
        fr: [
          "Cet outil à but pédagogique a pour vocation de faire des simulations d'allocations stratégiques long terme (entre 8 et 10 ans). Les informations données par cet outil le sont sans prendre en considération votre situation personnelle et notamment votre situation financière et votre profil de risque. Par exception, certaines classes d'actif peuvent ne pas vous être présentées compte tenu du profil financier sélectionné.",
          'Les rendements réels qui pourront être réalisés pourront différer de ceux présentés dans ces simulations.',
        ],
        en: [
          'This tool aims at making long-term (8 to 10 years) strategic asset allocations simulations with an educational purpose. Information available in this tool are provided without taking into account your personal situation, and more particularly your financial situation, and your risk profile. On an exception basis, some asset classes may be not presented to you in order to be in line with the selected financial profile.',
          'Actual returns, which can be achieved, can differ from those presented in these simulations.',
        ],
        nl: [],
      },
      ASIA: {
        en: [
          'This tool/document aims at making long-term (8 to 10 years) strategic asset class allocations simulations with an educational and illustrative purpose for further discussion, and are not recommendations or investment advice.',
          'Past performance should not be taken as an indication of future performance. Information available in this tool/document are provided without taking into account your personal situation, and more particularly your financial situation, knowledge & experience, and your risk profile. Asset class allocations presented may not be in line with your specific investment objectives, financial situation and particular needs.',
        ],
      },
      SPA: {
        en: [
          'This tool aims at making long-term (8 to 10 years) strategic asset allocations simulations with an educational purpose. Information available in this tool are provided without taking into account your personal situation, and more particularly your financial situation, and your risk profile. On an exception basis, some asset classes may be not presented to you in order to be in line with the selected financial profile.',
          'Actual returns, which can be achieved, can differ from those presented in these simulations.',
        ],
        es: [
          'Esta herramienta tiene como objetivo realizar simulaciones de distribuciones estratégicas de activos a largo plazo, con un propósito informativo y formativo. La información proporcionada por esta herramienta no tiene en cuenta su situación personal, ni su situación financiera, ni su perfil de riesgo, ni su perfil de inversión. Las rentabilidades que puedan lograrse con la distribución de activos propuesta pueden diferir de las rentabilidades presentadas en estas simulaciones.',
          'Rentabilidades pasadas no garantizan rentabilidades futuras.',
        ],
      },
      CH: {
        fr: [
          "Cet outil à but pédagogique a pour vocation de faire des simulations d'allocations stratégiques long terme (entre 8 et 10 ans). Les informations données par cet outil le sont sans prendre en considération votre situation personnelle et notamment votre situation financière et votre profil de risque. Par exception, certaines classes d'actif peuvent ne pas vous être présentées compte tenu du profil financier sélectionné.",
          'Les rendements réels qui pourront être réalisés pourront différer de ceux présentés dans ces simulations.',
        ],
        en: [
          'This tool aims at making long-term (8 to 10 years) strategic asset allocations simulations with an educational purpose. Information available in this tool are provided without taking into account your personal situation, and more particularly your financial situation, and your risk profile. On an exception basis, some asset classes may be not presented to you in order to be in line with the selected financial profile.',
          'Actual returns, which can be achieved, can differ from those presented in these simulations.',
        ],
      },
    },
  },
  {
    displayOn: 'analysis',
    disclaimer: {
      FRA: {
        fr: [
          'Simulations communiquées à titre illustratif uniquement, ne constituant pas un indicateur fiable quant aux performances futures.',
          "Source : BNP Paribas Wealth Management. Rentabilités attendues brutes (hors frais d'entrée, fiscalité et autres frais) et volatilités prévisionnelles long terme (8-10 ans) annualisées.",
          "Les rentabilités attendues ne sont pas calculées sur la base de données passées mais de façon objective et prospective en essayant d'estimer le « rendement d'équilibre » de chaque classe d'actifs, i.e. la tendance centrale sur une très longue période de temps (sur 10 à 20 ans) autour de laquelle les rendements de marché ont tendance à fluctuer.",
          "Pour le calcul des volatilités et corrélations prévisionnelles, en ligne avec le consensus financier, nous nous basons sur les données historiques, à savoir les variations des rendements historiques par classe d'actifs et les corrélations historiques entre les classes d'actifs calculées sur 15 années de données mensuelles.",
        ],
        en: [
          'Simulations provided for illustrative purposes only, which are no guarantee for future performances.',
          'Source: BNP Paribas Wealth Management; expected returns (gross, i.e. excluding tax and transaction costs) and volatilities p.a. over the LT (8-10 years).',
          'Expected returns are not calculated with past performance simulations but are based on a prospective basis, by trying to estimate the « equilibrium return » of each asset class, i.e. the central tendency over a very long period of time (from 10 to 20 years) around which market returns will tend to fluctuate.',
          'To calculate expected volatilities and correlations, we use past data in line with the financial consensus, that is the variability of historical returns per asset class and historical correlations across asset classes over 15 years of monthly data.',
        ],
      },
      BEL: {
        fr: [
          'Simulations communiquées à titre illustratif uniquement, ne constituant pas un indicateur fiable quant aux performances futures.',
          "Source : BNP Paribas Wealth Management. Rentabilités attendues brutes (hors frais d'entrée, fiscalité et autres frais) et volatilités prévisionnelles long terme (8-10 ans) annualisées.",
          "Les rentabilités attendues ne sont pas calculées sur la base de données passées mais de façon objective et prospective en essayant d'estimer le « rendement d'équilibre » de chaque classe d'actifs, i.e. la tendance centrale sur une très longue période de temps (sur 10 à 20 ans) autour de laquelle les rendements de marché ont tendance à fluctuer.",
          "Pour le calcul des volatilités et corrélations prévisionnelles, en ligne avec le consensus financier, nous nous basons sur les données historiques, à savoir les variations des rendements historiques par classe d'actifs et les corrélations historiques entre les classes d'actifs calculées sur 15 années de données mensuelles.",
        ],
        en: [
          'Simulations provided for illustrative purposes only, which are no guarantee for future performances.',
          'Source: BNP Paribas Wealth Management; expected returns (gross, i.e. excluding tax and transaction costs) and volatilities p.a. over the LT (8-10 years).',
          'Expected returns are not calculated with past performance simulations but are based on a prospective basis, by trying to estimate the « equilibrium return » of each asset class, i.e. the central tendency over a very long period of time (from 10 to 20 years) around which market returns will tend to fluctuate.',
          'To calculate expected volatilities and correlations, we use past data in line with the financial consensus, that is the variability of historical returns per asset class and historical correlations across asset classes over 15 years of monthly data.',
        ],
        nl: [
          'Bron : BNP Paribas Wealth Management. Proprietary Strategic-A model: potentiele bruto rendementen op jaarbasis en verwachte volatiliteit op lange termijn (8-10 jaar).',
          'Potentiele Verwachte rendementen worden niet berekend op basis van historische rendementen, maar zijn gebaseerd op een prospectieve basis, door te proberen het "evenwichtsrendement" van elke activaklasse te schatten, d.w.z. de centrale tendens over een zeer lange periode (8-10 jaar)  waarrond marktrendementen zullen fluctueren. Rendementen en volatiliteit vormen geen betrouwbare indicator voor toekomstig rendement en volatiliteit.',
        ],
      },
      ASIA: {
        en: [
          'Source: BNPP Wealth Management; expected returns (gross, i.e. excluding tax and transaction costs) and volatilities p.a. over the LT (8-10 years). To better understand concepts and methodology used in this page, you can refer to pages “Key metrics” and “The fundamentals of our methodology” in the present document.',
        ],
      },
      SPA: {
        en: [
          'Simulations provided for illustrative purposes only, which are no guarantee for future performances.',
          'Source: BNP Paribas Wealth Management; expected returns (gross, i.e. excluding tax and transaction costs) and volatilities p.a. over the LT (8-10 years).',
          'Expected returns are not calculated with past performance simulations but are based on a prospective basis, by trying to estimate the « equilibrium return » of each asset class, i.e. the central tendency over a very long period of time (from 10 to 20 years) around which market returns will tend to fluctuate.',
          'To calculate expected volatilities and correlations, we use past data in line with the financial consensus, that is the variability of historical returns per asset class and historical correlations across asset classes over 15 years of monthly data.',
        ],
        es: [
          'Simulaciones proporcionadas únicamente con fines ilustrativos.',
          'Fuente: BNPP WM; modelo propio Strategic A: rentabilidades esperadas (brutas) y volatilidades anuales a largo plazo (8-10 años).',
          'Las rentabilidades esperadas no se calculan en base a las rentabilidades pasadas, sino que se basan en un ejercicio de prospección, tratando de estimar la “rentabilidad de equilibrio” para cada una de las clases de activos. Es decir, la tendencia central en un período muy largo de tiempo, alrededor de la cual las rentabilidades del mercado tenderán a fluctuar.',
          'Para calcular las volatilidades y correlaciones esperadas, utilizamos datos pasados, en línea con el consenso financiero, es decir, la variabilidad de las rentabilidades históricas por clase de activo y las covarianzas históricas entre todas las clases de activos, con datos mensuales de los últimos 15 años.',
        ],
      },
      CH: {
        fr: [
          'Simulations communiquées à titre illustratif uniquement, ne constituant pas un indicateur fiable quant aux performances futures.',
          "Source : BNP Paribas Wealth Management. Rentabilités attendues brutes (hors frais d'entrée, fiscalité et autres frais) et volatilités prévisionnelles long terme (8-10 ans) annualisées.",
          "Les rentabilités attendues ne sont pas calculées sur la base de données passées mais de façon objective et prospective en essayant d'estimer le « rendement d'équilibre » de chaque classe d'actifs, i.e. la tendance centrale sur une très longue période de temps (sur 10 à 20 ans) autour de laquelle les rendements de marché ont tendance à fluctuer.",
          "Pour le calcul des volatilités et corrélations prévisionnelles, en ligne avec le consensus financier, nous nous basons sur les données historiques, à savoir les variations des rendements historiques par classe d'actifs et les corrélations historiques entre les classes d'actifs calculées sur 15 années de données mensuelles.",
        ],
        en: [
          'Simulations provided for illustrative purposes only, which are no guarantee for future performances.',
          'Source: BNP Paribas Wealth Management; expected returns (gross, i.e. excluding tax and transaction costs) and volatilities p.a. over the LT (8-10 years).',
          'Expected returns are not calculated with past performance simulations but are based on a prospective basis, by trying to estimate the « equilibrium return » of each asset class, i.e. the central tendency over a very long period of time (from 10 to 20 years) around which market returns will tend to fluctuate.',
          'To calculate expected volatilities and correlations, we use past data in line with the financial consensus, that is the variability of historical returns per asset class and historical correlations across asset classes over 15 years of monthly data.',
        ],
      },
    },
  },
  {
    displayOn: 'backtest',
    disclaimer: {
      FRA: {
        fr: [
          'Les rendements passés ne sont pas constants dans le temps et ne présagent pas des rendements futurs.',
          "Source : BNP Paribas Wealth Management. Rentabilités passées brutes (hors frais d'entrée, fiscalité et autres frais) incluant à la fois les variations de prix et les rendements courants. Rebalancement annuel fixe, tous les 1er janvier.",
        ],
        en: [
          'Past returns are not constant and are no guarantee for future returns.',
          'Source: BNP Paribas Wealth Management; past returns (gross, i.e. excluding tax and transaction costs) includes both price variations and yields. Annual rebalancing, every January, 1st.',
        ],
      },
      BEL: {
        fr: [
          'Les rendements passés ne sont pas constants dans le temps et ne présagent pas des rendements futurs.',
          "Source : BNP Paribas Wealth Management. Rentabilités passées brutes (hors frais d'entrée, fiscalité et autres frais) incluant à la fois les variations de prix et les rendements courants. Rebalancement annuel fixe, tous les 1er janvier.",
        ],
        en: [
          'Past returns are not constant and are no guarantee for future returns.',
          'Source: BNP Paribas Wealth Management; past returns (gross, i.e. excluding tax and transaction costs) includes both price variations and yields. Annual rebalancing, every January, 1st.',
        ],
        nl: [
          'Rendementen uit het verleden vormen geen betrouwbare indicator voor toekomstige rendementen.',
          'Bron : BNP Paribas Wealth Management. Proprietary Strategic-A model: historische bruto rendementen inclusief zowel de meer/minwaarden als de opbrengsten. Jaarlijkse herbalancing op 1 januari.',
          'Referentie indexen per activaklasse: Eurofonds (AFA), Overheidsobligaties US (Treasuries 7-10Y), Overheidsobligaties EU (EMTS), EM Obligaties USD (JPM GBI–EMBI+), Bedrijfsobligaties EU IG (BOFA ML EMU Corp 7-10Y), Bedrijfsobligaties EU HY (BOFA ML Euro HY), Bedrijfsobligaties US IG (Barclays US Corp. IG), Bedrijfsobligaties US HY (Barclays US Corp. HY), Aandelen Europa (DJSTOXX 600), Aandelen US (S&P 500), Aandelen EM (MSCI EM Global), Genoteerd vastgoed (EPRA Europe), Residentieel vastgoed (Eurostat), Bureau’s vastgoed (CBRE), Grondstoffen (S&P GSCI Global), Goud, Alternatieve strat. (HFRI Global), Private debt (S&P Leveraged Loan), Private Equity (Cambridge Associates), Private Real Estate (Cambridge Associates).',
        ],
      },
      ASIA: {
        en: [
          'Past returns are not constant and are no guarantee for future returns.',
          'Source: BNPP Wealth Management; past returns (gross, i.e. excluding tax and transaction costs) includes both price variations and yields. Annual rebalancing, every January, 1st. To better understand concepts and methodology used in this page, you can refer to pages “Key metrics” and “The fundamentals of our methodology” in the present document.',
          'Reference Index per asset class: Euro Fund (AFA), Government Bonds US (Treasuries 7-10Y), Government Bonds Europe (EMTS), EM Bonds USD (JPM GBI–EMBI+), Corporate Bonds Europe IG (BOFA ML EMU Corp 7-10Y), Corporate Bonds Europe HY (BOFA ML Euro HY), Corporate Bonds US IG (Barclays US Corp. IG), Corporate Bonds US HY (Barclays US Corp. HY), Equities Europe (DJSTOXX 600), Equities US (S&P 500), Equities EM (MSCI EM Global), Listed Real Estate (EPRA Europe), Real Estate Residential (Eurostat), Real Estate Offices (CBRE), Commodities (S&P GSCI Global), Gold, Alternative Strategies (HFRI Global), Private Debt (S&P Leveraged Loan), Private Equity (Cambridge Associates), Private Real Estate (Cambridge Associates)',
        ],
      },
      SPA: {
        en: [
          'Past returns are not constant and are no guarantee for future returns.',
          'Source: BNP Paribas Wealth Management; past returns (gross, i.e. excluding tax and transaction costs) includes both price variations and yields. Annual rebalancing, every January, 1st.',
        ],
        es: [
          'Las rentabilidades pasadas no son constantes y no garantizan rentabilidades futuras.',
          'Fuente: BNPP WM; modelo propio Strategic-A. Rentabilidades pasadas (brutas) incluyen variaciones de precios y de retornos. Rebalanceo anual, cada 1 de enero.',
        ],
      },
      CH: {
        fr: [
          'Les rendements passés ne sont pas constants dans le temps et ne présagent pas des rendements futurs.',
          "Source : BNP Paribas Wealth Management. Rentabilités passées brutes (hors frais d'entrée, fiscalité et autres frais) incluant à la fois les variations de prix et les rendements courants. Rebalancement annuel fixe, tous les 1er janvier.",
        ],
        en: [
          'Past returns are not constant and are no guarantee for future returns.',
          'Source: BNP Paribas Wealth Management; past returns (gross, i.e. excluding tax and transaction costs) includes both price variations and yields. Annual rebalancing, every January, 1st.',
        ],
      },
    },
  },
  {
    displayOn: 'forecast',
    disclaimer: {
      FRA: {
        fr: [
          'Source : BNP Paribas Wealth Management. Rentabilités attendues (incluant variations de prix et rendement courant) brutes qui ne prennent en compte ni la fiscalité ni les frais de transaction, et volatilités prévisionnelles long terme (8-10 ans) annualisées.',
          "Indices de référence par classe d'actifs : Fonds Euro (AFA), Obligations d'état US (Treasuries 7-10Y), Obligations d'état Europe (EMTS), Obligations Marchés Emergents USD (JPM GBI–EMBI+), Obligations d'entreprise IG (BOFA ML EMU Corp 7-10Y), Obligations d'entreprise Europe HY (BOFA ML Euro HY), Obligations d'entreprise US IG (Barclays US Corp. IG), Obligations d'entreprise US HY (Barclays US Corp. HY), Actions Europe (DJSTOXX 600), Actions US (S&P 500), Actions Marchés Emergents (MSCI EM Global), Immobilier coté (EPRA Europe), Immobilier résidentiel (Eurostat), Immobilier de Bureaux (CBRE), Matières Premières (S&P GSCI Global), Or, Stratégies alternatives (HFRI Global), Dette privée (S&P Leveraged Loan), Private Equity (Cambridge Associates), Private Real Estate (Cambridge Associates).",
          "Les rentabilités attendues ne sont pas calculées sur la base de données passées mais de façon objective et prospective en essayant d'estimer le « rendement d'équilibre » de chaque classe d'actifs, i.e. la tendance centrale sur une très longue période de temps (sur 10 à 20 ans) autour de laquelle les rendements de marché ont tendance à fluctuer.",
          "Pour le calcul des volatilités et corrélations prévisionnelles, en ligne avec le consensus financier, nous nous basons sur les données historiques, à savoir les variations des rendements historiques par classe d'actifs et les corrélations historiques entre les classes d'actifs calculées sur 15 années de données mensuelles.",
          'Ces prévisions ne constituent pas un indicateur fiable quant aux performances futures.',
        ],
        en: [
          'Source: BNP Paribas Wealth Management; gross expected returns (excluding tax and transaction costs) which include price variations and yield, and volatilities p.a. over the LT (8-10 years).',
          'Reference Index per asset class: Euro Fund (AFA), Government Bonds US (Treasuries 7-10Y), Government Bonds Europe (EMTS), EM Bonds USD (JPM GBI–EMBI+), Corporate Bonds Europe IG (BOFA ML EMU Corp 7-10Y), Corporate Bonds Europe HY (BOFA ML Euro HY), Corporate Bonds US IG (Barclays US Corp. IG), Corporate Bonds US HY (Barclays US Corp. HY), Equities Europe (DJSTOXX 600), Equities US (S&P 500), Equities EM (MSCI EM Global), Listed Real Estate (EPRA Europe), Real Estate Residential (Eurostat), Real Estate Offices (CBRE), Commodities (S&P GSCI Global), Gold, Alternative Strategies (HFRI Global), Private Debt (S&P Leveraged Loan), Private Equity (Cambridge Associates), Private Real Estate (Cambridge Associates).',
          'Expected returns are not calculated with past performances but are based on a prospective basis, by trying to estimate the « equilibrium return » of each asset class, i.e. the central tendency over a very long period of time (from 10 to 20 years) around which market returns will tend to fluctuate.',
          'To calculate expected volatilities and correlations, we use past data in line with the financial consensus, that is the variability of historical returns per asset class and historical correlations across asset classes over 15 years of monthly data.',
          'These forecasts are no guarantee for future performances.',
        ],
      },
      BEL: {
        fr: [
          'Source : BNP Paribas Wealth Management. Rentabilités attendues (incluant variations de prix et rendement courant) brutes qui ne prennent en compte ni la fiscalité ni les frais de transaction, et volatilités prévisionnelles long terme (8-10 ans) annualisées.',
          "Indices de référence par classe d'actifs : Fonds Euro (AFA), Obligations d'état US (Treasuries 7-10Y), Obligations d'état Europe (EMTS), Obligations Marchés Emergents USD (JPM GBI–EMBI+), Obligations d'entreprise IG (BOFA ML EMU Corp 7-10Y), Obligations d'entreprise Europe HY (BOFA ML Euro HY), Obligations d'entreprise US IG (Barclays US Corp. IG), Obligations d'entreprise US HY (Barclays US Corp. HY), Actions Europe (DJSTOXX 600), Actions US (S&P 500), Actions Marchés Emergents (MSCI EM Global), Immobilier coté (EPRA Europe), Immobilier résidentiel (Eurostat), Immobilier de Bureaux (CBRE), Matières Premières (S&P GSCI Global), Or, Stratégies alternatives (HFRI Global), Dette privée (S&P Leveraged Loan), Private Equity (Cambridge Associates), Private Real Estate (Cambridge Associates).",
          "Les rentabilités attendues ne sont pas calculées sur la base de données passées mais de façon objective et prospective en essayant d'estimer le « rendement d'équilibre » de chaque classe d'actifs, i.e. la tendance centrale sur une très longue période de temps (sur 10 à 20 ans) autour de laquelle les rendements de marché ont tendance à fluctuer.",
          "Pour le calcul des volatilités et corrélations prévisionnelles, en ligne avec le consensus financier, nous nous basons sur les données historiques, à savoir les variations des rendements historiques par classe d'actifs et les corrélations historiques entre les classes d'actifs calculées sur 15 années de données mensuelles.",
          'Ces prévisions ne constituent pas un indicateur fiable quant aux performances futures.',
        ],
        en: [
          'Source: BNP Paribas Wealth Management; gross expected returns (excluding tax and transaction costs) which include price variations and yield, and volatilities p.a. over the LT (8-10 years).',
          'Reference Index per asset class: Euro Fund (AFA), Government Bonds US (Treasuries 7-10Y), Government Bonds Europe (EMTS), EM Bonds USD (JPM GBI–EMBI+), Corporate Bonds Europe IG (BOFA ML EMU Corp 7-10Y), Corporate Bonds Europe HY (BOFA ML Euro HY), Corporate Bonds US IG (Barclays US Corp. IG), Corporate Bonds US HY (Barclays US Corp. HY), Equities Europe (DJSTOXX 600), Equities US (S&P 500), Equities EM (MSCI EM Global), Listed Real Estate (EPRA Europe), Real Estate Residential (Eurostat), Real Estate Offices (CBRE), Commodities (S&P GSCI Global), Gold, Alternative Strategies (HFRI Global), Private Debt (S&P Leveraged Loan), Private Equity (Cambridge Associates), Private Real Estate (Cambridge Associates).',
          'Expected returns are not calculated with past performances but are based on a prospective basis, by trying to estimate the « equilibrium return » of each asset class, i.e. the central tendency over a very long period of time (from 10 to 20 years) around which market returns will tend to fluctuate.',
          'To calculate expected volatilities and correlations, we use past data in line with the financial consensus, that is the variability of historical returns per asset class and historical correlations across asset classes over 15 years of monthly data.',
          'These forecasts are no guarantee for future performances.',
        ],
        nl: [
          'Bron : BNP Paribas Wealth Management. Proprietary Strategic-A model: potentiele bruto rendementen op jaarbasis (exclusief taksen en kosten) en verwachte volatiliteit op lange termijn (8-10 jaar).',
          'Potentiele Verwachte rendementen worden niet berekend op basis van historische rendementen, maar zijn gebaseerd op een prospectieve basis, door te proberen het "evenwichtsrendement" van elke activaklasse te schatten, d.w.z. de centrale tendens over een zeer lange periode (8-10 jaar)  waarrond marktrendementen zullen fluctueren. Rendementen en volatiliteit vormen geen betrouwbare indicator voor toekomstig rendement en volatiliteit.',
          'Referentie indexen per activaklasse: Eurofonds (AFA), Overheidsobligaties US (Treasuries 7-10Y), Overheidsobligaties EU (EMTS), EM Obligaties USD (JPM GBI–EMBI+), Bedrijfsobligaties EU IG (BOFA ML EMU Corp 7-10Y), Bedrijfsobligaties EU HY (BOFA ML Euro HY), Bedrijfsobligaties US IG (Barclays US Corp. IG), Bedrijfsobligaties US HY (Barclays US Corp. HY), Aandelen Europa (DJSTOXX 600), Aandelen US (S&P 500), Aandelen EM (MSCI EM Global), Genoteerd vastgoed (EPRA Europe), Residentieel vastgoed (Eurostat), Bureau’s vastgoed (CBRE), Grondstoffen (S&P GSCI Global), Goud, Alternatieve strat. (HFRI Global), Private debt (S&P Leveraged Loan), Private Equity (Cambridge Associates), Private Real Estate (Cambridge Associates).',
        ],
      },
      ASIA: {
        en: [
          'Source: BNPP Wealth Management; gross expected returns (excluding tax and transaction costs) which include price variations and yield, and volatilities p.a. over the LT (8-10 years). To better understand concepts and methodology used in this page, you can refer to pages “Key metrics” and “The fundamentals of our methodology” in the present document.',
          'Reference Index per asset class: Euro Fund (AFA), Government Bonds US (Treasuries 7-10Y), Government Bonds Europe (EMTS), EM Bonds USD (JPM GBI–EMBI+), Corporate Bonds Europe IG (BOFA ML EMU Corp 7-10Y), Corporate Bonds Europe HY (BOFA ML Euro HY), Corporate Bonds US IG (Barclays US Corp. IG), Corporate Bonds US HY (Barclays US Corp. HY), Equities Europe (DJSTOXX 600), Equities US (S&P 500), Equities EM (MSCI EM Global), Listed Real Estate (EPRA Europe), Real Estate Residential (Eurostat), Real Estate Offices (CBRE), Commodities (S&P GSCI Global), Gold, Alternative Strategies (HFRI Global), Private Debt (S&P Leveraged Loan), Private Equity (Cambridge Associates), Private Real Estate (Cambridge Associates).',
        ],
      },
      SPA: {
        en: [
          'Source: BNP Paribas Wealth Management; gross expected returns (excluding tax and transaction costs) which include price variations and yield, and volatilities p.a. over the LT (8-10 years).',
          'Reference Index per asset class: Euro Fund (AFA), Government Bonds US (Treasuries 7-10Y), Government Bonds Europe (EMTS), EM Bonds USD (JPM GBI–EMBI+), Corporate Bonds Europe IG (BOFA ML EMU Corp 7-10Y), Corporate Bonds Europe HY (BOFA ML Euro HY), Corporate Bonds US IG (Barclays US Corp. IG), Corporate Bonds US HY (Barclays US Corp. HY), Equities Europe (DJSTOXX 600), Equities US (S&P 500), Equities EM (MSCI EM Global), Listed Real Estate (EPRA Europe), Real Estate Residential (Eurostat), Real Estate Offices (CBRE), Commodities (S&P GSCI Global), Gold, Alternative Strategies (HFRI Global), Private Debt (S&P Leveraged Loan), Private Equity (Cambridge Associates), Private Real Estate (Cambridge Associates).',
          'Expected returns are not calculated with past performances but are based on a prospective basis, by trying to estimate the « equilibrium return » of each asset class, i.e. the central tendency over a very long period of time (from 10 to 20 years) around which market returns will tend to fluctuate.',
          'To calculate expected volatilities and correlations, we use past data in line with the financial consensus, that is the variability of historical returns per asset class and historical correlations across asset classes over 15 years of monthly data.',
          'These forecasts are no guarantee for future performances.',
        ],
        es: [
          'Fuente: BNPP WM; modelo propio Strategic A: rentabilidades brutas esperadas (excluyendo los impuestos y los costes de las operaciones) que incluyen variaciones de precios y de rendimiento, y volatilidades anuales a largo plazo (8-10 años).',
          'Indices de referencia por tipo de activo: Euro Fund (AFA), Bonos de Gobierno EEUU (Treasuries 7-10Y), Bonos de Gobierno Europa (EMTS), Bonos de Mercados Emergentes USD (JPM GBI–EMBI+), Bonos Corporativos Europa IG (BOFA ML EMU Corp 7-10Y), Bonos Corporativos Europa HY (BOFA ML Euro HY), Bonos Corporativos EEUU IG (Barclays US Corp. IG), Bonos Corporativos EEUU HY (Barclays US Corp. HY), Renta Variable Europa (DJSTOXX 600), Renta Variable EEUU (S&P 500), Renta Variable Mercados Emergentes (MSCI EM Global), Inmobiliario cotizado (EPRA Europe), Inmobiliario residencial (Eurostat), Inmobiliario Oficinas (CBRE), Materias Primas (S&P GSCI Global), Oro, Inversiones Alternativas (HFRI Global), Deuda Privada (S&P Leveraged Loan), Private Equity (Cambridge Associates), Private Real Estate (Cambridge Associates)',
          'Las rentabilidades esperadas no se calculan en base a las rentabilidades pasadas, sino que se basan en un ejercicio de prospección, tratando de estimar la “rentabilidad de equilibrio” para cada una de las clases de activos. Es decir, la tendencia central en un período muy largo de tiempo, alrededor de la cual las rentabilidades del mercado tenderán a fluctuar.',
          'Para calcular las volatilidades y correlaciones esperadas, utilizamos datos pasados, en línea con el consenso financiero, es decir, la variabilidad de las rentabilidades históricas por clase de activo y las covarianzas históricas entre todas las clases de activos, en un periodo de datos mensuales de 15 años.',
        ],
      },
      CH: {
        fr: [
          'Source : BNP Paribas Wealth Management. Rentabilités attendues (incluant variations de prix et rendement courant) brutes qui ne prennent en compte ni la fiscalité ni les frais de transaction, et volatilités prévisionnelles long terme (8-10 ans) annualisées.',
          "Indices de référence par classe d'actifs : Fonds Euro (AFA), Obligations d'état US (Treasuries 7-10Y), Obligations d'état Europe (EMTS), Obligations Marchés Emergents USD (JPM GBI–EMBI+), Obligations d'entreprise IG (BOFA ML EMU Corp 7-10Y), Obligations d'entreprise Europe HY (BOFA ML Euro HY), Obligations d'entreprise US IG (Barclays US Corp. IG), Obligations d'entreprise US HY (Barclays US Corp. HY), Actions Europe (DJSTOXX 600), Actions US (S&P 500), Actions Marchés Emergents (MSCI EM Global), Immobilier coté (EPRA Europe), Immobilier résidentiel (Eurostat), Immobilier de Bureaux (CBRE), Matières Premières (S&P GSCI Global), Or, Stratégies alternatives (HFRI Global), Dette privée (S&P Leveraged Loan), Private Equity (Cambridge Associates), Private Real Estate (Cambridge Associates).",
          "Les rentabilités attendues ne sont pas calculées sur la base de données passées mais de façon objective et prospective en essayant d'estimer le « rendement d'équilibre » de chaque classe d'actifs, i.e. la tendance centrale sur une très longue période de temps (sur 10 à 20 ans) autour de laquelle les rendements de marché ont tendance à fluctuer.",
          "Pour le calcul des volatilités et corrélations prévisionnelles, en ligne avec le consensus financier, nous nous basons sur les données historiques, à savoir les variations des rendements historiques par classe d'actifs et les corrélations historiques entre les classes d'actifs calculées sur 15 années de données mensuelles.",
          'Ces prévisions ne constituent pas un indicateur fiable quant aux performances futures.',
        ],
        en: [
          'Source: BNP Paribas Wealth Management; gross expected returns (excluding tax and transaction costs) which include price variations and yield, and volatilities p.a. over the LT (8-10 years).',
          'Reference Index per asset class: Euro Fund (AFA), Government Bonds US (Treasuries 7-10Y), Government Bonds Europe (EMTS), EM Bonds USD (JPM GBI–EMBI+), Corporate Bonds Europe IG (BOFA ML EMU Corp 7-10Y), Corporate Bonds Europe HY (BOFA ML Euro HY), Corporate Bonds US IG (Barclays US Corp. IG), Corporate Bonds US HY (Barclays US Corp. HY), Equities Europe (DJSTOXX 600), Equities US (S&P 500), Equities EM (MSCI EM Global), Listed Real Estate (EPRA Europe), Real Estate Residential (Eurostat), Real Estate Offices (CBRE), Commodities (S&P GSCI Global), Gold, Alternative Strategies (HFRI Global), Private Debt (S&P Leveraged Loan), Private Equity (Cambridge Associates), Private Real Estate (Cambridge Associates).',
          'Expected returns are not calculated with past performances but are based on a prospective basis, by trying to estimate the « equilibrium return » of each asset class, i.e. the central tendency over a very long period of time (from 10 to 20 years) around which market returns will tend to fluctuate.',
          'To calculate expected volatilities and correlations, we use past data in line with the financial consensus, that is the variability of historical returns per asset class and historical correlations across asset classes over 15 years of monthly data.',
          'These forecasts are no guarantee for future performances.',
        ],
      },
    },
  },
];
